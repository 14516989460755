/* colours */
$c-bg: #F3F3FA;
$c-bg-dark: rgb(51, 65, 85);
$c-slate-100: rgb(241, 245, 249);
$c-slate-200: rgb(226, 232, 240);
$c-slate-300: rgb(203, 213, 225);
$c-slate-400: rgb(148, 163, 184);
$c-slate-500: rgb(100, 116, 139);
$c-slate-600: rgb(71, 85, 105);
$c-slate-700: rgb(51, 65, 85);
$c-slate-800: rgb(30, 41, 59);
$c-slate-900: rgb(15, 23, 42);
$c-bg-verydark: rgb(15, 23, 42);

$c-text: #475569;
$c-text-light: #64748b;
$c-text-dark: #333440;
$c-text-lighter: #d8dfe9;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #73B0F4;
$c-dark: #1f2937;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-discord: #7289da;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #d95565;
$c-green: #10b981;
$c-blue: #73B0F4;
$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-yellow: #f5e50d;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-black: #000000;
$c-darkpurple: #556CB6;
