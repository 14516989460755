@import 'components/global.scss';

.date {
  
  width: 100%;
  z-index: 1;
  max-width: $scale13;
  margin-bottom: $scale1;

  &:last-of-type {

    margin-bottom: 0;

  }

  > div:first-of-type {

    margin-top: -$scale5;

  }
  
  input {

    margin-bottom: 0;

  }

  span {

    width: 2em !important;

  }
}