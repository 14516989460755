.message-list {
    /*max-width: 500px;*/
    width: 100%;
    padding-bottom: 1rem;
  }
  
  .message-container {
    display: flex;
    flex-direction: row;
    padding: 0.25rem;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 0.15rem;
  }
  
  .user, .date {
    font-size: 0.625rem;
    color: #30099a;
  }

  
  .user {
    min-width: 75px;
    text-align: left;
  }
  
  .message {
    flex-grow: 1;
    font-size: 0.825rem;
    color: #515050;
  }