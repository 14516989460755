@import 'components/global.scss';

.header {

  position: relative;
  width: auto;
  z-index: 3;
  background: white;
  padding: $scale2;
  font-weight: 600;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;

  @media (max-width: $mobile){

    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale;
    border: none;

  }

  @media (min-width: $mobile){

    h1 {

      font-size: $scale1;
      
    }
  }
}

.headerDark {

  position: relative;
  width: auto;
  z-index: 3;
  background: $c-bg-verydark;
  padding: $scale2;
  padding-bottom: $scale-3;
  font-weight: 600;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;
  
  
  color: $c-text-lighter;

  h1 {
    margin-top: $scale-3*-1;
    margin-bottom: $scale-3*-1;
    line-height: 3rem;
    
  }

  @media (max-width: $mobile){

    color: $c-text-lighter;
    text-align: center;
    background: $c-bg-verydark;
    padding: $scale $scale;
    border: none;

  }

  @media (min-width: $mobile){

    h1 {

      font-size: $scale1;
      margin-top: $scale-3*-1;
      margin-bottom: $scale-3*-1;
      
    }
  }
}