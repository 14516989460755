@import 'components/global.scss';

.subnav {

  position: relative;
  padding: $scale-3 $scale4 0;
  margin: $scale-3*-1 $scale2*-1 $scale2 $scale1*-2;
  background: white;
  border-top: 1px solid $c-border;

}

.subnavdark {

  position: relative;
  padding: $scale-3 $scale2 0;
  margin: $scale3*-1 $scale2*-1 $scale2 $scale1*-2;
  background: $c-slate-900;
  border-top: 1px solid $c-dark;

}

.center {
  display: flex;
  justify-content: center;
}

.item {

  color: $c-text;
  display: inline-block;
  padding-bottom: $scale-4;
  margin-right: $scale2;
  text-decoration: none;

  &.active {

    border-bottom: 2px solid $c-blue;
  
  }
}

.itemdark_OLD {

  color: $c-twitter;
  display: inline-block;
  padding-bottom: $scale-4;
  margin-right: $scale-3;
  text-decoration: none;
  font-size: small;

  &.active {

    border-bottom: 2px solid $c-blue;
  
  }
}

.itemdark {

  color: $c-yellow;
  display: inline-block;
  padding-bottom: $scale-4;
  margin-right: $scale-2;
  text-decoration: none;
  font-size: small;

  &.active {

    border-bottom: 2px solid $c-yellow;
  
  }
}

