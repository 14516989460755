.chat-app {
  margin-bottom: 2rem;
}
.app-header {
    padding: 0.3rem;
    background-color: #282c34;
    color: white;
  }
  
  .app-header > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    max-height: 468px;
    overflow: auto;
  }