
  
  .chatInput {
    width: 98%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    border-width: 1px;
    padding: 0.25rem;
    margin: 0.5rem;
    line-height: 1rem;
  }